import { NgModule } from '@angular/core';
import { NoPreloading, RouterModule, Routes } from '@angular/router';
import { LoginGuard } from './services/login.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'member',
    pathMatch: 'full'
  },
  {
    path: 'sys',
    loadChildren: () => import('./routes/sys/sys.module').then(m => m.SysPageModule)
  },
  {
    path: 'member',
    loadChildren: () => import('./routes/member/member.module').then(m => m.MemberPageModule),
    canActivate: [LoginGuard],

  },
  {
    path: 'pay',
    canActivate: [LoginGuard],
    loadChildren: () => import('./routes/pay/pay.module').then(m => m.PayPageModule)
  },
  {
    path: 'home',
    canActivate: [LoginGuard],
    loadChildren: () => import('./routes/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'tabs',
    canActivate: [LoginGuard],
    loadChildren: () => import('./routes/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'common',
    canActivate: [LoginGuard],
    loadChildren: () => import('./routes/common/common.module').then(m => m.CommonPageModule)
  },
  {
    path: 'cartrade',
    canActivate: [LoginGuard],
    loadChildren: () => import('./routes/cartrade/cartrade.module').then(m => m.CartradePageModule)
  },
  {
    path: 'carstock',
    canActivate: [LoginGuard],
    loadChildren: () => import('./routes/carstock/carstock.module').then(m => m.CarstockPageModule)
  },
  {
    path: 'sync',
    canActivate: [LoginGuard],
    loadChildren: () => import('./routes/sync/sync.module').then(m => m.SyncPageModule)
  },
  {
    path: 'luck',
    canActivate: [LoginGuard],
    loadChildren: () => import('./routes/luck/luck.module').then(m => m.LuckPageModule)
  },
  {
    path: 'financia',
    canActivate: [LoginGuard],
    loadChildren: () => import('./routes/financia/financia.module').then(m => m.FinanciaPageModule)
  },
  {
    path: 'pm',
    loadChildren: () => import('./routes/pm/pm.module').then(m => m.PmPageModule)
  },
  {
    path: 'sysgate',
    canActivate: [LoginGuard],
    loadChildren: () => import('./routes/sys-gate/sys-gate.module').then(m => m.SysGatePageModule)
  },
  {
    path: 'auction',
    loadChildren: () => import('./routes/auction/auction.module').then(m => m.AuctionPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'ocr',
    loadChildren: () => import('./routes/ocr/ocr.module').then(m => m.OcrPageModule),
    canActivate: [LoginGuard]
  },
  {
    path: 'a',
    loadChildren: () => import('./routes/a/a.module').then( m => m.APageModule)
  }
];
@NgModule({
  imports: [
    //PreloadAllModules
    RouterModule.forRoot(routes, { preloadingStrategy: NoPreloading })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
