import { Pipe, PipeTransform } from '@angular/core';
import { DateUtils } from '@core/utils/dateUtils';

@Pipe({
  name: 'datimeStr'
})
export class DatimeStrPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    let v: any = value;
    if (!v) {
      return '';
    }
    if (args == null || (args != null && args.length <= 0)) {
      let length = v.length;
      let result = `${v.substr(0, 4)}-${v.substr(4, 2)}-${v.substr(6, 2)} `;
      result += `${v.substr(8, 2)}:${v.substr(10, 2)}:${v.substr(12, 2)}`;
      return result;
    } else {
      let arg0 = args[0];

      if (arg0 == 'date') {
        return DateUtils.toStrYYYYMMDD(v);
      }
      if (arg0 == 'year') {
        return DateUtils.toStrYYYY(v);
      }
      if (arg0 == 'month') {
        return DateUtils.toStrMM(v);
      }
      if (arg0 == 'day') {
        return DateUtils.toStrDD(v);
      }
      return arg0;
    }
  }
}
